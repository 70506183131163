import type { ReactNode } from 'react';
import cn from 'classnames';

import { Footnote } from '../../../uilib/Typography/Typography';

import { ReactComponent as StarIcon } from '../../../svg/img/star.svg';
import s from './Progressbar.module.scss';

export function Progressbar({
  from,
  current,
  to,
  footer = {},
  className,
  theme = 'light',
}: {
  current: number;
  from: number;
  to: number;
  className?: string;
  theme?: 'dark' | 'light';
  footer?: {
    left?: ReactNode;
    right?: ReactNode;
  };
}) {
  const progress = (current - from) / (to - from);
  const { right, left } = footer;
  const isDark = theme === 'dark';

  return (
    <div className={className}>
      <div className={s.progressbar}>
        {progress > 0 && (
          <div
            className={cn(s.progress, s.progressCurrent, isDark && s.progressCurrentDark)}
            style={{ flexGrow: progress }}
          />
        )}
        <StarIcon className={cn(s.star, isDark && s.starDark)}/>
        <div
          className={cn(s.progress, isDark && s.progressDark)}
          style={{ flexGrow: 1 - progress }}
        />
      </div>
      {Boolean(left || right) && (
        <Footnote className={s.footer} weight="medium">
          <span>{left}</span>
          <span>{right}</span>
        </Footnote>
      )}
    </div>
  );
}