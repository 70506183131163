import type { ComponentType, HTMLAttributes } from 'react';
import cn from 'classnames';

import s from './Button.module.scss';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  size?: 'sm' | 'md';
  type?: 'tertiary' | 'filled';
  IconLeft?: ComponentType<{ className?: string }>;
  fullWidth?: boolean;
}

export function Button({ size, IconLeft, type, className, children, fullWidth }: ButtonProps) {
  size ||= 'md';
  type ||= 'filled';

  return (
    <button className={cn(s.root, s[type], s[size], fullWidth && s.fullWidth, className)}>
      {IconLeft && <IconLeft className={s.iconLeft}/>}
      {children && <span className={s.content}>{children}</span>}
    </button>
  );
}