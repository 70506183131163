import { Trans } from 'react-i18next';
import useSWR from 'swr';

import { WithColoredUI } from '../WithColoredUI';
import { publicUrl } from '../../../utils/url';
import { createSrcSet } from '../../../utils/createSrcSet';
import { Callout, Heading } from '../../../uilib/Typography/Typography';
import { Button } from '../Button/Button';
import { RedPacketTaskReachLeague } from '../RedPacketTaskReachLeague/RedPacketTaskReachLeague';
import { RedPacketTaskPremium } from '../RedPacketTaskPremium/RedPacketTaskPremium';
import {
  RedPacketTaskInviteFriends,
} from '../RedPacketTaskInviteFriends/RedPacketTaskInviteFriends';
import { RedPacketTaskShimmer } from '../RedPacketTask/RedPacketTask';
import { useApiClient } from '../../providers/ApiClientProvider/context';
import { findLeagueById } from '../../leagues/leagues';
import { RedPacketTaskSecret } from '../RedPacketTaskSecret/RedPacketTaskSecret';

import { ReactComponent as ChevronRightIcon } from '../../../svg/img/chevron-right18.svg';
import { ReactComponent as FaqIcon } from '../../../svg/img/fag20.svg';
import s from './RedPacketPage.module.scss';

export function RedPacketPage() {
  const client = useApiClient();
  const { data } = useSWR('red-packet-tasks', client.getRedPacketTasks.bind(client));

  return (
    <WithColoredUI color="#251517">
      <div className={s.root}>
        <div className={s.inner}>
          <section className={s.image}>
            <img
              draggable={false}
              className={s.imageBg}
              alt=""
              src={publicUrl('/red-packet/envelope/background.png')}
              width={380}
              height={330}
            />
            <img
              draggable={false}
              className={s.imageImg}
              alt=""
              width={192}
              height={200}
              src={publicUrl('/red-packet/envelope/envelope.png')}
              srcSet={createSrcSet(['', '@2x', '@3x'].map(suffix => {
                return publicUrl(`/red-packet/envelope/envelope${suffix}.png`);
              }))}
            />
          </section>
          <section className={s.titleContainer}>
            <Heading className={s.title} weight="bold">
              <Trans i18nKey="RedPacketPage.Title"/>
            </Heading>
            <Callout className={s.subtitle} weight="regular">
              <Trans i18nKey="RedPacketPage.Subtitle"/>
            </Callout>
            <Button
              className={s.goToOnboarding}
              type="tertiary"
              size="sm"
              IconLeft={FaqIcon}
              onClick={() => {
                // todo: assign when the onboarding is ready
              }}
            >
              <Trans i18nKey="RedPacketPage.GoToOnboarding"/>
            </Button>
          </section>
          <section className={s.tasks}>
            {data
              ? data.tasks.map((task, idx) => {
                const sharedProps = { id: task.id };
                if (task.type === 'premium') {
                  return <RedPacketTaskPremium key={idx} {...sharedProps}/>;
                }
                if (task.type === 'league') {
                  const league = findLeagueById(task.data.id);
                  if (league) {
                    return (
                      <RedPacketTaskReachLeague
                        {...sharedProps}
                        key={idx}
                        days={task.data.days}
                        league={league.title}
                        icon={league.img}
                      />
                    );
                  }
                }
                if (task.type === 'referrals') {
                  return (
                    <RedPacketTaskInviteFriends
                      key={idx}
                      count={task.data.count}
                      {...sharedProps}
                    />
                  );
                }
                if (task.type === 'secret') {
                  return <RedPacketTaskSecret key={idx} {...sharedProps}/>;
                }
              }, [])
              : new Array(2).fill(0).map((_, i) => (
                <RedPacketTaskShimmer key={i}/>
              ))}
          </section>
          <div className={s.divider}/>
          <a
            href="#"
            draggable={false}
            className={s.terms}
            onClick={(e) => {
              e.preventDefault();
              // TODO: use openLink when the link is known. Also update in href
            }}
          >
            <Trans i18nKey="RedPacketPage.Terms"/>
            <ChevronRightIcon/>
          </a>
        </div>
      </div>
    </WithColoredUI>
  );
}