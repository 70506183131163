import { publicUrl } from '../../utils/url';
import { createSrcSet } from '../../utils/createSrcSet';

// TODO: for all images add blurDataURL.

export interface League {
  id: number;
  title: string;
  gradients: {
    hero: string;
    list: string;
  };
  img: {
    size: number;
    src: string;
    srcSet: string;
  },
}

const greyHeroGradient = 'radial-gradient(39.44% 49.87% at 50.13% 60.56%, rgba(213, 221, 237, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)';

export const initialLeague = {
  title: 'Beginner League',
  gradients: {
    hero: greyHeroGradient,
  },
  img: {
    src: publicUrl('/streaks/leagues/beginner/Beginner.png'),
    srcSet: createSrcSet(['', '@2x', '@3x'].map(suffix => {
      return publicUrl(`/streaks/leagues/beginner/Beginner${suffix}.png`);
    })),
  },
};

const leagues: League[] = [
  {
    id: 1,
    title: 'Bronze League',
    gradients: {
      hero: 'radial-gradient(39.44% 49.87% at 50.13% 60.56%, rgba(201, 158, 122, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)',
      list: 'radial-gradient(49.87% 39.44% at 50.13% 60.56%, rgba(201, 158, 122, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
    },
    img: createLeagueImg('Bronze'),
  },
  {
    id: 2,
    title: 'Silver League',
    img: createLeagueImg('Silver'),
    gradients: {
      hero: greyHeroGradient,
      list: 'radial-gradient(49.87% 39.44% at 50.13% 60.56%, rgba(213, 221, 237, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
    },
  },
  {
    id: 3,
    title: 'Gold League',
    img: createLeagueImg('Gold'),
    gradients: {
      hero: 'radial-gradient(39.44% 49.87% at 50.13% 60.56%, rgba(197, 170, 109, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)',
      list: 'radial-gradient(49.87% 39.44% at 50.13% 60.56%, rgba(197, 170, 109, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
    },
  },
  {
    id: 4,
    title: 'Platinum League',
    img: createLeagueImg('Platinum'),
    gradients: {
      hero: greyHeroGradient,
      list: 'radial-gradient(49.87% 39.44% at 50.13% 60.56%, rgba(213, 221, 237, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
    },
  },
  {
    id: 5,
    title: 'Diamond League',
    img: createLeagueImg('Diamond'),
    gradients: {
      hero: 'radial-gradient(39.44% 49.87% at 50.13% 60.56%, rgba(114, 105, 226, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)',
      list: 'radial-gradient(49.87% 39.44% at 50.13% 60.56%, rgba(114, 105, 226, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
    },
  },
  {
    id: 6,
    title: 'Master League',
    img: createLeagueImg('Master'),
    gradients: {
      hero: 'radial-gradient(39.44% 49.87% at 50.13% 60.56%, rgba(98, 254, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)',
      list: 'radial-gradient(49.87% 39.44% at 50.13% 60.56%, rgba(98, 254, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
    },
  },
];

function createLeagueImg(name: string) {
  const baseUrl = `/streaks/leagues/${name.toLowerCase()}/${name}`;
  return {
    size: 150,
    src: publicUrl(`${baseUrl}.png`),
    srcSet: createSrcSet(['', '@2x', '@3x'].map(suffix => {
      return `${baseUrl}${suffix}.png`;
    })),
  };
}

export function findLeagueById(id: number): League | undefined {
  return leagues.find(l => l.id === id);
}
