import { useTranslation } from 'react-i18next';

import { RedPacketTask } from '../RedPacketTask/RedPacketTask';

import { ReactComponent as PeopleAnnounceIcon } from '../../../svg/img/friends-24.svg';

export function RedPacketTaskInviteFriends({ count, id }: { id: number, count: number }) {
  const { t } = useTranslation();
  return (
    <RedPacketTask
      id={id}
      type="completable"
      title={t('RedPacketInviteFriendsTask.Title', { count })}
      text={t('RedPacketInviteFriendsTask.Text')}
      headerIcon={PeopleAnnounceIcon}
      infoPath={`/red-packet/invite?count=${count}`}
    />
  );
}