import { useTranslation } from 'react-i18next';

import { RedPacketTask } from '../RedPacketTask/RedPacketTask';

export function RedPacketTaskReachLeague({ league, icon, days, id }: {
  id: number;
  days: number;
  league: string;
  icon: { src: string; srcSet: string };
}) {
  const { t } = useTranslation();
  return (
    <RedPacketTask
      id={id}
      type="completable"
      title={t('RedPacketReachLeagueTask.Title', { league })}
      text={t('RedPacketReachLeagueTask.Text', { count: days })}
      headerIcon={icon}
    />
  );
}