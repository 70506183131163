import { useTranslation } from 'react-i18next';

import { RedPacketTask } from '../RedPacketTask/RedPacketTask';
import { StarIcon } from '../StarIcon';

export function RedPacketTaskPremium(props: { id: number }) {
  const { t } = useTranslation();
  return (
    <RedPacketTask
      {...props}
      type="completable"
      title={t('RedPacketPremiumTask.Title')}
      text={t('RedPacketPremiumTask.Text')}
      headerIcon={props => (
        <StarIcon
          {...props}
          colorFrom="#7490F8"
          colorTo="#D270CB"
        />
      )}
    />
  );
}