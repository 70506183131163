import React, { PropsWithChildren, useEffect, useState } from 'react';
import { RecentlyLaunched } from '../../app/_components/RecentlyLaunched/RecentlyLaunched';
import { setRecentlyLaunchedContainerHeight } from '../../app/_components/RecentlyLaunched/utils';
import { useConfigContext } from '../../dataSource/ConfigContext';
import { getItemFromStorage } from '../../hooks/hooks';
import { useIsRedPacketEnabled } from '../../app/hooks/flags/useIsRedPacketEnabled';
import { RedPacketWidget } from '../../app/_components/RedPacketWidget/RedPacketWidget';

import styles from './styles.module.scss';
import { Tabs } from './Tabs';

export const TWATabs: React.FC<PropsWithChildren> = () => {
  const [
    isRecentlyLaunchedExists,
    setRecentlyLaunchedExists,
  ] = useState(window.isRecentlyLaunchedEnabled);
  const redPacketEnabled = useIsRedPacketEnabled();

  const appConfig = useConfigContext();

  useEffect(() => {
    const platform = window.Telegram.WebApp.platform;
    const isRecentlyLaunchedEnabledOnPlatform = appConfig?.config?.recently_launched_enabled?.data
      .some(({ attributes }) => {
        return attributes.name === platform;
      });

    getItemFromStorage('recentlyLaunched')
      .then((res) => {
        if (res) {
          const showRecentlyLaunched = Boolean(isRecentlyLaunchedEnabledOnPlatform) &&
            (Boolean(window.isRecentlyLaunchedExists) || Boolean(res));

          if (showRecentlyLaunched) {
            window.isRecentlyLaunchedEnabled = true;
            setRecentlyLaunchedContainerHeight();
          }

          setRecentlyLaunchedExists(showRecentlyLaunched);
        }
      })
      .catch((e) => {
        if (process.env.NODE_ENV === 'development') {
          // console.log(e)
        }
      });
  }, [appConfig]);

  return (
    <>
      <div className={styles.placeholder}/>
      {redPacketEnabled && (
        <section className={styles.redPacket}>
          <RedPacketWidget/>
        </section>
      )}
      {(window.isRecentlyLaunchedEnabled || isRecentlyLaunchedExists) && <RecentlyLaunched/>}
      <Tabs/>
    </>
  );
};
