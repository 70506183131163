import { Trans } from 'react-i18next';

import { Body, SubHeadline } from '../../../../uilib/Typography/Typography';
import { StreaksPanel } from '../../../_components/StreaksPanel/StreaksPanel';
import { DefaultShimmer } from '../../../_components/DefaultShimmer/DefaultShimmer';

import s from './TitlePanel.module.scss';

export interface TitlePanelProps {
  title: string;
  subtitle: string;
  price: number;
}

export function TitlePanel({ title, subtitle, price }: TitlePanelProps) {
  return (
    <StreaksPanel className={s.root}>
      <div className={s.texts}>
        <Body className={s.title} weight="regular">
          {title}
        </Body>
        <SubHeadline className={s.subtitle} weight="regular" level={2}>
          {subtitle}
        </SubHeadline>
      </div>
      <Body className={s.price} weight="regular">
        <Trans i18nKey="PocketCollectionPage.PricePoints" count={price}/>
      </Body>
    </StreaksPanel>
  );
}

TitlePanel.Shimmer = () => <DefaultShimmer className={s.shimmer} height={60}/>