import type { ComponentType } from 'react';
import cn from 'classnames';

import { Heading, type HeadingProps } from '../../../uilib/Typography/Typography';
import { TextWithIcon } from '../TextWithIcon';
import { DefaultShimmer } from '../DefaultShimmer/DefaultShimmer';

import s from './RedPacketTaskHeader.module.scss';

export type RedPacketTaskHeaderIcon =
  | { src: string; srcSet: string }
  | ComponentType<{ className?: string }>;

interface Props extends Omit<HeadingProps, 'weight' | 'type'> {
  icon: RedPacketTaskHeaderIcon;
}

export function RedPacketTaskHeader({
  children,
  icon: Icon,
  className,
  ...rest
}: Props) {
  const icon = 'src' in Icon
    ? <img className={s.image} alt="" {...Icon}/>
    : <Icon className={s.image}/>;

  return (
    <Heading className={cn(s.root, className)} weight="bold" type="h4" {...rest}>
      {typeof children === 'string' ? (
        <TextWithIcon icon={icon}>
          {children}
        </TextWithIcon>
      ) : (
        <>
          {children}{' '}
          {icon}
        </>
      )}
    </Heading>
  );
}

export const RedPacketTaskHeaderShimmer = () => (
  <div className={cn(s.root, s.shimmer)}>
    <DefaultShimmer height={24} style={{ flex: '1 0 0' }}/>
    <DefaultShimmer rounded={true} height={24} width={24}/>
  </div>
);
