import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { ReactNode } from 'react';
import cn from 'classnames';
// import useSWRMutation from 'swr/mutation';

import { Body } from '../../../uilib/Typography/Typography';
import { Button } from '../Button/Button';
import { RedPacketPanel } from '../RedPacketPanel/RedPacketPanel';
import {
  RedPacketTaskText,
  RedPacketTaskTextShimmer,
} from '../RedPacketTaskText/RedPacketTaskText';
import {
  RedPacketTaskHeader,
  type RedPacketTaskHeaderIcon,
  RedPacketTaskHeaderShimmer,
} from '../RedPacketTaskHeader/RedPacketTaskHeader';
import { SystemRipple } from '../SystemRipple/SystemRipple';
import { DefaultShimmer } from '../DefaultShimmer/DefaultShimmer';
// import { useApiClient } from '../../providers/ApiClientProvider/context';

import { ReactComponent as InfoIcon } from '../../../svg/img/info-24.svg';
import s from './RedPacketTask.module.scss';

export function RedPacketTask({ title, headerIcon, text, type, infoPath, id }: {
  id: number;
  title: string;
  type: 'completable' | 'purchasable';
  text: ReactNode;
  infoPath?: string;
  headerIcon: RedPacketTaskHeaderIcon;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const client = useApiClient();
  // const {} = useSWRMutation(`complete-red-packet-task-${id}`, () => {
  //   return client.completeRedPacketTask(id);
  // });

  return (
    <RedPacketPanel className={s.root}>
      {infoPath && (
        <SystemRipple
          className={s.info}
          onClick={() => {
            navigate(infoPath);
          }}
        >
          <InfoIcon className={s.infoIcon}/>
        </SystemRipple>
      )}
      <RedPacketTaskHeader className={cn(infoPath && s.headerWithInfo)} icon={headerIcon}>
        {title}
      </RedPacketTaskHeader>
      <RedPacketTaskText>{text}</RedPacketTaskText>
      <div className={s.footer}>
        <Button className={s.button} fullWidth>
          <Body weight="semi-bold">
            {t(type === 'completable' ? 'RedPacketTask.CheckProgress' : 'RedPacketTask.Purchase')}
          </Body>
        </Button>
      </div>
    </RedPacketPanel>
  );
}

export const RedPacketTaskShimmer = () => (
  <RedPacketPanel className={s.root}>
    <RedPacketTaskHeaderShimmer/>
    <RedPacketTaskTextShimmer/>
    <div className={s.footer}>
      <DefaultShimmer height={50} style={{ borderRadius: 10 }}/>
    </div>
  </RedPacketPanel>
);