import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Callout, Footnote, Heading } from '../../../uilib/Typography/Typography';
import { createSrcSet } from '../../../utils/createSrcSet';
import { publicUrl } from '../../../utils/url';
import { SystemRipple } from '../SystemRipple/SystemRipple';
import { Progressbar } from '../Progressbar/Progressbar';
import { useLocaleContext } from '../../providers/LocaleProvider/context';
import { useUTCToday } from '../../hooks/dates/useUTCToday';
import { TextWithIcon } from '../TextWithIcon';

import { ReactComponent as ArrowRightRoundedIcon } from '../../../svg/img/arrow-right-rounded.svg';
import s from './RedPacketWidget.module.scss';

export function RedPacketWidget({ theme }: { theme?: 'streaks' }) {
  const { t } = useTranslation();
  const { locale } = useLocaleContext();
  const navigate = useNavigate();

  const dayMs = 24 * 60 * 60 * 1000;
  const today = useUTCToday();
  const startDate = new Date('2025-01-27T00:00:00.000Z');
  const endDate = new Date('2025-02-12T00:00:00.000Z');
  const daysRemaining = (endDate.getTime() - today) / dayMs;

  const isStreaks = theme === 'streaks';

  return (
    <div className={cn(s.root, isStreaks && s.rootStreaks)}>
      <SystemRipple
        className={s.ripple}
        onClick={() => {
          navigate('/red-packet');
        }}
      >
        <div className={s.header}>
          <Heading className={s.title} weight="bold" type="h4">
            <TextWithIcon
              icon={
                <img
                  className={s.headerIcon}
                  alt=""
                  src={publicUrl('/red-packet/icon/icon.png')}
                  srcSet={createSrcSet(['', '@2x', '@3x'].map(suffix => {
                    return publicUrl(`/red-packet/icon/icon${suffix}.png`);
                  }))}
                  width={24}
                  height={24}
                />
              }
            >
              {t('RedPacketBanner.Title')}
            </TextWithIcon>
          </Heading>
          <ArrowRightRoundedIcon className={cn(s.action, isStreaks && s.actionStreaks)}/>
        </div>
        <Callout className={s.content} weight="regular">
          {t('RedPacketBanner.Text')}
        </Callout>
        {daysRemaining >= 0 && (
          <div className={s.footer}>
            <Progressbar
              theme={window.Telegram.WebApp.colorScheme === 'dark' ? 'light' : 'dark'}
              from={startDate.getTime() / dayMs}
              current={today / dayMs}
              to={endDate.getTime() / dayMs}
              footer={{
                left: (
                  <Footnote weight="medium">
                    {t('RedPacketBanner.ProgressbarLeft')}
                  </Footnote>
                ),
                right: (
                  <Footnote weight="medium">
                    {endDate.toLocaleDateString(locale, {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    })}
                  </Footnote>
                ),
              }}
            />
          </div>
        )}
      </SystemRipple>
    </div>
  );
}