import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import type { ReactNode } from 'react';
import { DefaultAnimatePresence } from '../DefaultAnimatePresence';

import { DefaultShimmer } from '../DefaultShimmer/DefaultShimmer';
import { Heading } from '../../../uilib/Typography/Typography';
import { Ripple } from '../../../uilib/Ripple/Ripple';
import { StreaksProgress } from '../StreaksProgress/StreaksProgress';
import { useStreaks } from './useStreaks';
import { useIsSetOnce } from '../../hooks/useIsSetOnce';
import { StreaksTimerDaily } from '../StreaksTimerDaily/StreaksTimerDaily';

import System from '../../../services/System';

import { ReactComponent as ArrowRightRounded } from '../../../svg/img/arrow-right-rounded.svg';
import s from './StreaksBanner.module.scss';

export function StreaksBanner() {
  const navigate = useNavigate();
  const data = useStreaks();
  const vanishedOnce = useIsSetOnce(!data);

  let content: ReactNode;
  if (data === 'loading') {
    // Data may change from time to time, and we don't want this loader to appear again and again.
    if (!vanishedOnce) {
      content = (
        <>
          <div className={s.headerShimmerWrap}>
            <div className={s.headerShimmerTitleWrap}>
              <DefaultShimmer height={30}/>
              <DefaultShimmer height={30} width="50%"/>
            </div>
            <DefaultShimmer className={s.headerShimmerArrow}/>
          </div>
          <StreaksProgress data="loading"/>
          <DefaultShimmer className={s.footerShimmer}/>
        </>
      );
    }
  } else if (data) {
    const { streak, nextLeague, isDailyCompleted } = data;

    content = (
      <>
        <div className={s.link}>
          <ArrowRightRounded className={s.linkIcon}/>
        </div>
        <Heading className={s.title} weight="bold">
          {isDailyCompleted
            ? streak === 0
              ? <Trans i18nKey="StreaksBanner.Title.NoProgress"/>
              : <Trans i18nKey="StreaksBanner.Title.NDaysProgress" count={streak}/>
            : <Trans i18nKey="StreaksBanner.Title.CTA" count={streak + 1}/>}
        </Heading>
        {nextLeague && (
          <StreaksProgress
            data={{
              from: 0,
              current: streak,
              to: nextLeague.streakDays,
              nextLeague: nextLeague,
            }}
          />
        )}
        {!isDailyCompleted && (
          <div className={s.footer}>
            <StreaksTimerDaily/>
          </div>
        )}
      </>
    );
  }

  return (
    <DefaultAnimatePresence>
      {content && (
        <section className={s.root}>
          <div className={s.panel}>
            <Ripple
              className={s.panelInner}
              onClick={() => {
                System.delayAction(() => {
                  navigate('/streaks');
                });
              }}
            >
              {content}
            </Ripple>
          </div>
        </section>
      )}
    </DefaultAnimatePresence>
  );
}