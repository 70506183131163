import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { WithColoredUI } from '../WithColoredUI';
import { Body, Heading } from '../../../uilib/Typography/Typography';

import { ReactComponent as FriendsIcon } from '../../../svg/img/friends-24.svg';
import { ReactComponent as OneIcon } from '../../../svg/img/circle-number-one-40.svg';
import { ReactComponent as TwoIcon } from '../../../svg/img/circle-number-two-40.svg';
import { ReactComponent as ThreeIcon } from '../../../svg/img/circle-number-three-40.svg';
import s from './RedPacketInvitePage.module.scss';

export function RedPacketInvitePage() {
  const count = Number(new URLSearchParams(useLocation().search).get('count')) || 5;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const points = [
    [OneIcon, t('RedPacketInvitePage.Step1')],
    [TwoIcon, t('RedPacketInvitePage.Step2', { count })],
    [ThreeIcon, t('RedPacketInvitePage.Step3')],
  ] as const;

  useEffect(() => {
    const { MainButton: mb } = window.Telegram.WebApp;

    mb.setParams({
      text: t('RedPacketInvitePage.InviteButton'),
      is_visible: true,
      is_active: true,
      has_shine_effect: true,
    });
    const handler = () => {
      navigate('/referral-programs');
    };
    mb.onClick(handler);

    return () => {
      mb.hide();
      mb.offClick(handler);
    };
  }, [t, navigate]);

  return (
    <WithColoredUI color="bg_color">
      <div className={s.root}>
        <div className={s.inner}>
          <section className={s.imageSection}>
            <div className={s.imageBlock}>
              <FriendsIcon className={s.image}/>
            </div>
          </section>
          <Heading className={s.title} weight="bold">
            {t('RedPacketInvitePage.Title', { count })}
          </Heading>
          <div className={s.list}>
            {points.map(([Icon, text], idx) => (
              <div className={s.listItem} key={idx}>
                <Icon className={s.listItemNum}/>
                <Body className={s.listItemText} weight="regular">{text}</Body>
              </div>
            ))}
          </div>
        </div>
      </div>
    </WithColoredUI>
  );
}